import React, { Component } from 'react'
import Table from 'react-bootstrap/Table'
import './TableStyle.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import PaginationBar from './PaginationBar'
import TimeFormatter from '../newTimeformatter/TimeFormatter';
import SyncIcon from '@mui/icons-material/Sync';
import { Modal, Spinner } from 'react-bootstrap';
import moment from 'moment';
import { RxCross2 } from "react-icons/rx";
import Hexapi from '../HexAPI/Hexapi';
import Select from '../Selectlist/select';
import Swal from 'sweetalert2';
// import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import DoneIcon from '@mui/icons-material/Done';
// import Modal from "@mui/material/Modal";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Pagination from '@mui/material/Pagination';
import Othercategory from '../../othercategory/Additionlisttypedata';
import { Category } from '@mui/icons-material';
import Additionlisttypedata from '../../othercategory/Additionlisttypedata';
import axios from 'axios';
import ImportFile from '../Excel/ImportFile'
import ExportFile from '../Excel/ExportFile'

var oldData = []

export default class ProductDatatable extends Component {
    constructor() {
        super()
        this.audioRef = React.createRef()
        this.state = {
            rowData: [], isDataFound: false, currentPaginationPage: 0, showPaginationRows: 0,
            isShowSearchBox: false, searchValue: '', showaudio: false, Status: false, oldData: '',
            rowuserid: '', joinus: '', origin: '', origindata: [], joindata: [], OpenAddScreen: false,
            windowwidth: window.innerWidth, name: '', sku: '', description: '', Weight: '', Length: '',
            Width: "", Height: "", mrp: "", WholesalePrice: '', OverseasPrice: '', Category: "", Subcategory: '',
            Images:null, SuitableForModel: '', SuitableForSubModel: '', SuitableForPartReference: '', CompanyCode: '',
            WarehouseLocation: '', RackSection: '', RackCode: '', PouchTubeCode: '', BoxCode: '', HSNCode: 0,
            GST: 0, Make: '', ProductCondition: '', Brand: '', CountryOfOrigin: '', ConversionRate: 0, MOQ: 0,
            VisibilityDomestic: 0, VisibilityOverseas: 0, ProductId: -1, screen: "", Totalpages: 0, CurrentPage: 1,
            Categorydata: [], subcategorydata: [], SuitableForModeldata: [], SuitableForSubModeldata: [],
            CompanyCodedata: [], WarehouseLocationdata: [], RackSectiondata: [], RackCodedata: [], PouchTubeCodedata: [],
            BoxCodedata: [], HSNCodedata: [], GSTdata: [], Makedata: [], ProductConditiondata: [], Branddata: [],
            CountryOfOrigindata: [], ConversionRatedata: [], MOQdata: [], SuitableForPartReferencedata: [],
            ColumnData: [], Columnname: '',
            ImageArr:[],
            showimage:false,
            currentimage:null,
            fetchRsUrl: `${sessionStorage.getItem('Apipathurl')}fetchrsdataV2.php`
        }
        this.handleDelete=this.handleDelete.bind(this)
        this.handlechangeinput=this.handlechangeinput.bind(this)
    }

    handleshowimage=(image)=>{
        this.setState({showimage:true,currentimage:image})
    }
    componentDidMount() {
        this.setState({ ColumnData: this.props.ColumnNames })
        let obj1 = {
            'query': `[dbo].[Seetech_Web_Proc_ProductMaster_FormGetDatav2]`,
            'queryArr': []
        }
        Hexapi(obj1).then((res) => {
            console.log(res);
            this.setState({
                Categorydata: res.category, subcategorydata: res.subcategory, SuitableForModeldata: res.suitableformodel,
                SuitableForSubModeldata: res.suitableforsubmodel, CompanyCodedata: res.companycode, WarehouseLocationdata: res.warehouselocation,
                RackSectiondata: res.racksection, RackCodedata: res.rackcode, PouchTubeCodedata: res["pouch/tubecode"], BoxCodedata: res.boxcode,
                HSNCodedata: res.hsncode, GSTdata: res.gst, Makedata: res.make, ProductConditiondata: res.productcondition, Branddata: res.brand,
                CountryOfOrigindata: res.countryoforigin, ConversionRatedata: res.conversionrate, MOQdata: res.moq,
            })
        })
        const updatedData = this.props.data.map((item) => ({
            Delete: item.Delete,
            Edit: item.Edit,
            ...item
        }))

        oldData = updatedData
        this.setState({ rowData: updatedData, isDataFound: true })
        this.props.pagination && this.setState({ showPaginationRows: this.props.pagination.rowsPerPage })
        let obj = {
            'query': `[dbo].[Seetech_Web_Proc_ProductMaster_GetData] @UserID='{0}',@Search='{1}',@Page='{2}'`,
            'queryArr': [`6`, `-1`, '1']
        }
        Hexapi(obj).then((res) => {

            const pages = Math.ceil(res["totalcount"][0].totalcount / 20);
            if (res["totalcount"][0].totalcount < 20) {
                this.setState({ Totalpages: 1 })
            } else {
                this.setState({ Totalpages: pages })
            }
        })

        window.addEventListener('resize', this.handleWindowSize);
    }
    reloadTable() {
        console.log('reload')
    }
    // other category data

    handlecategorychange=(e)=>{
     this.setState({ Columnname: e.target.value })
     console.log(e.target.value)
    }
    handleWindowSize = () => {
        this.setState({ windowwidth: window.innerWidth });
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSize);
    }
    getHeader(data) {
        const header = Object.keys(data[0])
        return header.map((s) => {
            // console.log(heading)
            {
                   // if (s !== "recid") {
                if (s == "registeredcontactnumber") {
                    return <th>Registered Contact Number</th>
                }
                return (
                    <th>{s}</th>
                )
            }
        }
            // }
        )
    }
    clearSearch(e) {
        this.setState({ rowData: oldData, isDataFound: true, isShowSearchBox: false, searchValue: '' })
    }
    setDate(date) {
        if (date != undefined) {
            // return date.split(' ')[0]
            // console.log(date)
            var transformdate = date.split(".")[0];
            // console.log(transformdate)
            //   console.log(date);
            var exitdate = TimeFormatter(transformdate);
            // console.log(exitdate);
            var visitorinfo = this.state.visitorinfo
            //   console.log(visitorinfo)
            // console.log(this.state.visitorinfo)
            var newReverseDate =
                exitdate.ShiftTime +
                "," +
                exitdate.userSplitedDate[2] +
                "-" +
                exitdate.ShiftMonth +
                "-" +
                exitdate.CurYear.toString().slice(-2);
            // console.log(newReverseDate);
            return newReverseDate;
        }
    }

    handleDelete(index) {
        const updatedImages = [...this.state.ImageArr];
        updatedImages.splice(index, 1);
        console.log(updatedImages,"updatedimages")
        this.setState({ ImageArr: updatedImages });
        console.log(updatedImages.join(","),"stringformat")
        this.setState({ Images: updatedImages.join(",")})
    }
    
    handlechangeinput=async(e)=>{
        console.log(e.target.files[0])
        const dataimage= e.target.files[0]
        const formdata= new FormData()
        formdata.append("file",dataimage)
        const imagedata= await axios.post(`${sessionStorage.getItem('Apipathurl')}ImageUpload.php`,formdata,{
            headers: { "Content-Type": "multipart/form-data" },
        })
        // console.log(imagedata.data,"imagedata")
        // this.setState(prevState => ({
        //     ImageArr: [...prevState.ImageArr,imagedata.data]
        //   }));
        this.state.ImageArr.push(imagedata.data)
        console.log(this.state.ImageArr,"imagearr")
        const updatedimage=[...this.state.ImageArr].join(",")
        console.log(updatedimage,"dataupdatedimage")
        // console.log(newimagedata,"new")
        this.setState({Images:updatedimage})
    }
    EditProductData = () => {
        console.log(this.state.sku,"SKU")
        console.log(this.state.name,"name")
        console.log(this.state.description,"description")
        console.log(this.state.Weight,"Weight")
        console.log(this.state.Length,"Length")
        console.log(this.state.Width,"Width")
        console.log(this.state.Height,"Height")
        console.log(this.state.WholesalePrice,"WholesalePrice")
        console.log(this.state.OverseasPrice,"OverseasPrice")
        console.log(this.state.Category,"Category")
        console.log(this.state.Subcategory,"Subcategory")
        console.log(this.state.Images,"Images")
        console.log(this.state.PouchTubeCode,"PouchTubeCode")
        console.log(this.state.BoxCode,"BoxCode")
        console.log(this.state.SuitableForModel,"SuitableForModel")
        console.log(this.state.SuitableForSubModel,"SuitableForSubModel")
        console.log(this.state.SuitableForPartReference,"SuitableForPartReference")
        console.log(this.state.CompanyCode,"CompanyCode")
        console.log(this.state.WarehouseLocation,"WarehouseLocation")
        console.log(this.state.RackCode,"RackCode")
        console.log(this.state.RackSection,"RackSection")
        console.log(this.state.HSNCode,"HSNCode")
        console.log(this.state.GST,"GST")
        console.log(this.state.Make,"Make")
        console.log(this.state.ProductCondition,"ProductCondition")
        console.log(this.state.Brand,"Brand")
        console.log(this.state.CountryOfOrigin,"CountryOfOrigin")
        console.log(this.state.ConversionRate,"ConversionRate")
        console.log(this.state.MOQ,"MOQ")

        if (this.state.sku != "" && this.state.name != "" && this.state.description != "" && this.state.Weight != "" && this.state.Length != "" && this.state.Width != "" && this.state.Height != ""
            && this.state.mrp != "" && this.state.WholesalePrice != "" && this.state.OverseasPrice != "" && this.state.Category != "" && this.state.Subcategory != "" && this.state.Images != ""
            && this.state.SuitableForModel != "" && this.state.SuitableForSubModel != "" && this.state.SuitableForPartReference != "" && this.state.CompanyCode != "" && this.state.WarehouseLocation != "" && this.state.RackCode != "" && this.state.RackSection != ""
            && this.state.PouchTubeCode != "" && this.state.BoxCode != "" && this.state.HSNCode != 0 && this.state.HSNCode != '' && this.state.GST != 0 && this.state.GST != '' && this.state.Make != "" && this.state.ProductCondition != ""
            && this.state.Brand != "" && this.state.CountryOfOrigin != "" && this.state.ConversionRate != 0 && this.state.ConversionRate != '' && this.state.MOQ != 0 && this.state.MOQ != '') {

            let obj = {
                'query': ` [dbo].[Seetech_Web_Proc_ProductMaster_Edit]
                        @ProductID='{0}',
                         @SKU='{1}',
                         @Name='{2}',
                         @Description='{3}',
                         @Weight='{4}',
                         @Length='{5}',
                         @Width='{6}',
                         @Height='{7}',
                         @MRP='{8}',
                         @WholesalePrice='{9}',
                         @OverseasPrice='{10}',
                         @Category='{11}',
                         @Subcategory='{12}',
                         @Images='{13}',
                         @SuitableForModel='{14}',
                         @SuitableForSubModel='{15}',
                         @SuitableForPartReference='{16}',
                         @CompanyCode='{17}',
                         @WarehouseLocation='{18}',
                         @RackSection='{19}',
                         @RackCode='{20}',
                         @PouchTubeCode='{21}',
                         @BoxCode='{22}',
                         @HSNCode='{23}',
                         @GST='{24}',
                         @Make='{25}',
                         @ProductCondition='{26}',
                         @Brand='{27}',
                         @CountryOfOrigin='{28}',
                         @ConversionRate='{29}',
                         @MOQ='{30}',
                         @VisibilityDomestic='{31}',
                         @VisibilityOverseas='{32}'`,
                'queryArr': [
                    `${this.state.ProductId}`, `${this.state.sku}`, `${this.state.name}`, `${this.state.description}`, `${this.state.Weight}`, `${this.state.Length}`,
                    `${this.state.Width}`, `${this.state.Height}`, `${this.state.mrp}`, `${this.state.WholesalePrice}`, `${this.state.OverseasPrice}`, `${this.state.Category}`,
                    `${this.state.Subcategory}`, `${this.state.Images}`, `${this.state.SuitableForModel}`, `${this.state.SuitableForSubModel}`, `${this.state.SuitableForPartReference}`, `${this.state.CompanyCode}`,
                    `${this.state.WarehouseLocation}`, `${this.state.RackCode}`, `${this.state.RackSection}`, `${this.state.PouchTubeCode}`, `${this.state.BoxCode}`, `${this.state.HSNCode}`,
                    `${this.state.GST}`, `${this.state.Make}`, `${this.state.ProductCondition}`, `${this.state.Brand}`, `${this.state.CountryOfOrigin}`, `${this.state.ConversionRate}`,
                    `${this.state.MOQ}`, `${this.state.VisibilityDomestic}`, `${this.state.VisibilityOverseas}`,
                ]
            }
            Hexapi(obj).then((res) => {
                console.log(res,"images")
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: res[""][0]["msg"],
                    showConfirmButton: false,
                    timer: 1500
                }).then(() => {
                    this.props.callback(true)
                })
            });
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please fill all Details',
            })
        }
    }
    setProduct = (data) => {
        const imagesdata= data.images
        console.log(imagesdata)
        var mapdata = []
        if(imagesdata != ''){
            const newimages=imagesdata.split(",")
            var mapdata=newimages.map((ele)=>{
                console.log(ele)
                if(ele !='' && ele != undefined){
                    const data=ele.replace("/wp-content","")
                    return data;
                }else{
                    return
                }
            })
        }
        console.log(mapdata)
        this.setState({ImageArr : mapdata})
        this.setState({
            name: data.name, sku: data.sku, description: data.description, Weight: data.width, Length: data.length,
            Width: data.weight, Height: data.height, mrp: data.mrp, WholesalePrice: data.wholesaleprice, OverseasPrice: data.overseasprice, Category: data.category, Subcategory: data.subcategory,
            Images: mapdata, SuitableForModel: data.suitableformodel, SuitableForSubModel: data.suitableforsubmodel, SuitableForPartReference: data.suitableforpartreference, CompanyCode: data.companycode,
            WarehouseLocation: data.warehouselocation, RackSection: data.racksection, RackCode: data.rackcode, PouchTubeCode: data["pouch/tubecode"], BoxCode: data.boxcode, HSNCode: data.hsncode,
            GST: data.gst, Make: data.make, ProductCondition: data.productcondition, Brand: data.brand, CountryOfOrigin: data.countryoforigin,
            ConversionRate: data.conversionrate, MOQ: data.moq, VisibilityDomestic: data.visibilitydomestic, VisibilityOverseas: data.visibilityoverseas, ProductId: data.recid,
        })
        this.setState({ OpenAddScreen: true });
    }

    DeleteProduct = (Id) => {
        Swal.fire({
            text: " Are youm sure to Delete Product ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            customClass: {
                container: 'custom-swal-container', // Add a custom class to the container
            },
        }).then((result) => {
            if (result.isConfirmed) {
                let obj = {
                    'query': `[dbo].[Seetech_Web_Proc_ProductMaster_Delete] @UserID='{0}',@ProductID='{1}'`,
                    'queryArr': [`${sessionStorage.getItem('userid')}`, `${Id}`]
                }
                Hexapi(obj).then((res) => {
                    console.log(res)
                    this.props.callback(true)
                })
            }
        });

    }

    getRowsData(data) {
        // console.log(data)
        var newTableData = [].concat(data)
        let newData = newTableData
        if (this.props.pagination) {
            newData = newTableData.filter((row, index) => {
                let rowPerPage = this.state.showPaginationRows < 0 ? newTableData.length : this.state.showPaginationRows
                let start = this.state.currentPaginationPage * rowPerPage
                let end = (this.state.currentPaginationPage + 1) * rowPerPage
                if (index >= start && index < end) return true
            })
        }
        return newData.map((row, i) => {
            return (
                <tr key={`row-`+i} onClick={() => {
                    // this.setState({membership:true});
                    this.props.onRowClick(row)
                }} >

                    {
                        Object.keys(row).map((heading) => {
                            if (row[heading] == null) {
                                row[heading] = ''
                            }

                            if (typeof row[heading] != 'object' && heading != "status" && heading != "Edit" && heading != "Delete") {
                                // return <td key={row[heading]} dangerouslySetInnerHTML={{ __html: row[heading] }} />
                                return <td  dangerouslySetInnerHTML={{ __html: row[heading] }} />
                                // return <td >{row[heading]}</td>
                            }
                            else if (heading == "Edit") {
                                return <td  >
                                    <span onClick={(e) => { e.stopPropagation(); this.setProduct(row); this.setState({ screen: 'edit' }) }}>
                                        <EditIcon style={{color:'rgb(66 137 165)'}}/>
                                    </span>
                                </td>
                            }

                            else if (heading == "Delete") {
                                return <td  >
                                    <span onClick={(e) => { e.stopPropagation(); this.DeleteProduct(row.recid) }}>
                                        <DeleteIcon style={{color:'red'}}/>
                                    </span>
                                </td>
                            }

                            else if (heading == "status") {
                                if (row["status"] == 0) {
                                    return (
                                        <td >
                                            <span onClick={() => {
                                                this.setState({ membership: true, rowuserid: row["userid"] })

                                            }}> <DoneIcon /> </span>
                                            <span onClick={() => { this.Rejectrequest(row["userid"]) }}> <CloseIcon />  </span>
                                        </td>
                                    )
                                }
                                else if (row["status"] == 1) {
                                    return <td  >Approved</td>
                                }
                                else {
                                    return <td >Rejected</td>
                                }
                            }
                            else {
                                // return <td >{this.setDate(row[heading].date) }</td>
                                // return <td key={row[heading]} dangerouslySetInnerHTML={{ __html: this.setDate(row[heading].date) }} />
                                return <td  dangerouslySetInnerHTML={{ __html: this.setDate(row[heading].date) }} />
                            }
                        }
                        )
                    }
                </tr>
            )
        })
    }
    AddProduct = (userid) => {
        console.log(this.state.Images,"ProductCondition")
        if (this.state.sku != "" && this.state.name != "" && this.state.description != "" && this.state.Weight != "" && this.state.Length != "" && this.state.Width != "" && this.state.Height != ""
            && this.state.mrp != "" && this.state.WholesalePrice != "" && this.state.OverseasPrice != "" && this.state.Category != "" && this.state.Subcategory != "" && this.state.Images != ""
            && this.state.SuitableForModel != "" && this.state.SuitableForSubModel != "" && this.state.SuitableForPartReference != "" && this.state.CompanyCode != "" && this.state.WarehouseLocation != "" && this.state.RackCode != "" && this.state.RackSection != ""
            && this.state.PouchTubeCode != "" && this.state.BoxCode != "" && this.state.HSNCode != 0 && this.state.HSNCode != '' && this.state.GST != 0 && this.state.GST != '' && this.state.Make != "" && this.state.ProductCondition != ""
            && this.state.Brand != "" && this.state.CountryOfOrigin != "" && this.state.ConversionRate != 0 && this.state.ConversionRate != '' && this.state.MOQ != 0 && this.state.MOQ != '') {
            let obj = {
                'query': ` [dbo].[Seetech_Web_Proc_ProductMaster_Add]
                             @SKU='{0}',
                             @Name='{1}',
                             @Description='{2}',
                             @Weight='{3}',
                             @Length='{4}',
                             @Width='{5}',
                             @Height='{6}',
                             @MRP='{7}',
                             @WholesalePrice='{8}',
                             @OverseasPrice='{9}',
                             @Category='{10}',
                             @Subcategory='{11}',
                             @Images='{12}',
                             @SuitableForModel='{13}',
                             @SuitableForSubModel='{14}',
                             @SuitableForPartReference='{15}',
                             @CompanyCode='{16}',
                             @WarehouseLocation='{17}',
                             @RackSection='{18}',
                             @RackCode='{19}',
                             @PouchTubeCode='{20}',
                             @BoxCode='{21}',
                             @HSNCode='{22}',
                             @GST='{23}',
                             @Make='{24}',
                             @ProductCondition='{25}',
                             @Brand='{26}',
                             @CountryOfOrigin='{27}',
                             @ConversionRate='{28}',
                             @MOQ='{29}',
                             @VisibilityDomestic='{30}',
                             @VisibilityOverseas='{31}'`,
                'queryArr': [
                    `${this.state.sku}`, `${this.state.name}`, `${this.state.description}`, `${this.state.Weight}`, `${this.state.Length}`,
                    `${this.state.Width}`, `${this.state.Height}`, `${this.state.mrp}`, `${this.state.WholesalePrice}`, `${this.state.OverseasPrice}`, `${this.state.Category}`,
                    `${this.state.Subcategory}`, `${this.state.Images}`, `${this.state.SuitableForModel}`, `${this.state.SuitableForSubModel}`, `${this.state.SuitableForPartReference}`, `${this.state.CompanyCode}`,
                    `${this.state.WarehouseLocation}`, `${this.state.RackSection}`, `${this.state.RackCode}`, `${this.state.PouchTubeCode}`, `${this.state.BoxCode}`, `${this.state.HSNCode}`,
                    `${this.state.GST}`, `${this.state.Make}`, `${this.state.ProductCondition}`, `${this.state.Brand}`, `${this.state.CountryOfOrigin}`, `${this.state.ConversionRate}`,
                    `${this.state.MOQ}`, `${this.state.VisibilityDomestic}`, `${this.state.VisibilityOverseas}`
                ]
            }
            Hexapi(obj).then((res) => {
                console.log(res)
                // this.props.callback(true)
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: res[""][0]["msg"],
                    showConfirmButton: false,
                    timer: 1500
                }).then(() => {
                    this.props.callback(true)
                })
            });
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please fill all Details',
            })
        }
    }
    storeSearch(e) {
        // let value = e.target.value
        // this.setState({ searchValue: value })

        if (this.state.Columnname != "") {
            let obj = {
                'query': `[dbo].[Seetech_Web_Proc_ProductMaster_searchdata] @Column='{0}',@SearchValue='{1}'`,
                'queryArr': [`${this.state.Columnname}`, `${e}`]
            }
            Hexapi(obj).then((res) => {
                console.log(res);

                if(res[""].length > 0){
                    const updatedData = res[""].map((item) => ({
                        Delete: item.Delete,
                        Edit: item.Edit,
                        ...item
                    }))
                    this.setState({ rowData: updatedData })
                }else{
                    this.setState({isDataFound:false})
                }

            })
        }
        else {
            let obj = {
                'query': `[dbo].[Seetech_Web_Proc_ProductMaster_GetData] @UserID='{0}',@Search='{1}',@Page='{2}'`,
                'queryArr': [`6`, `${this.state.searchValue}`, '1']
            }
            Hexapi(obj).then((res) => {
                const updatedData = res[""].map((item) => ({
                    Delete: item.Delete,
                    Edit: item.Edit,
                    ...item
                }))
                console.log(updatedData)
                this.setState({ rowData: updatedData })
            });
        }

        // console.log(oldData)

        // let applyFilter = Object.keys(oldData[0])

        // // console.log(value)
        // // console.log(applyFilter)
        // if (value == '') {
        //     if (oldData.length > 0) {
        //         console.log(oldData)
        //         this.setState({ rowData: oldData, isDataFound: true })
        //         // console.log(rowData)
        //     } else {
        //         this.setState({ isDataFound: false })
        //     }
        // } else {
        //     if ((oldData.filter((row) => {
        //         if (Array.isArray(applyFilter)) {
        //             let StrArr = []
        //             for (let i = 0; i < applyFilter.length; i++) {
        //                 StrArr.push(row[`${applyFilter[i]}`])
        //                 // console.log(StrArr.join(' ').toString().toLowerCase().includes(value.toLowerCase()))
        //                 // console.log(StrArr)
        //             }
        //             return StrArr.join(' ').toString().toLowerCase().includes(value.toLowerCase())
        //         } else {
        //             return (oldData[`${applyFilter[0]}`]).toString().toLowerCase().includes(value.toLowerCase())
        //         }
        //     })).length > 0) {
        //         this.setState({
        //             rowData: (oldData.filter((row) => {
        //                 if (Array.isArray(applyFilter)) {
        //                     let StrArr = []
        //                     for (let i = 0; i < applyFilter.length; i++) {
        //                         StrArr.push(row[`${applyFilter[i]}`])
        //                         // console.log(StrArr)
        //                     }

        //                     return StrArr.join(' ').toString().toLowerCase().includes(value.toLowerCase())
        //                     // console.log(StrArr)
        //                 } else {
        //                     return (oldData[`${applyFilter[0]}`]).toString().toLowerCase().includes(value.toLowerCase())()
        //                     // console.log(StrArr)
        //                 }
        //             }))
        //             , isDataFound: true
        //         }, () => {
        //             this.setState({ currentPaginationPage: 0 })
        //         })


        //     } else {
        //         this.setState({ isDataFound: false })
        //     }
        // }
    }
    Addform = () => {
        this.setState({
            name: '', sku: '', description: '', Weight: '', Length: '',
            Width: '', Height: '', mrp: '', WholesalePrice: '', OverseasPrice: '', Category: '', Subcategory: '',
            Images: '', SuitableForModel: '', SuitableForSubModel: '', SuitableForPartReference: '', CompanyCode: '',
            WarehouseLocation: '', RackSection: '', RackCode: '', PouchTubeCode: '', BoxCode: '', HSNCode: 0,
            GST: 0, Make: '', ProductCondition: '', Brand: '', CountryOfOrigin: '',
            ConversionRate: 0, MOQ: 0, VisibilityDomestic: '', VisibilityOverseas: '', ProductId: -1,
        })
        this.setState({ OpenAddScreen: true, screen: 'add' })
    }
    fetchPage = (event, value) => {

        this.setState({ CurrentPage: value, isDataFound: false });
        let obj = {
            'query': `[dbo].[Seetech_Web_Proc_ProductMaster_GetData] @UserID='{0}',@Search='{1}',@Page='{2}'`,
            'queryArr': [`6`, `-1`, ` ${value}`]
        }
        Hexapi(obj).then((res) => {
            console.warn(res);
            const updatedData = res[""].map((item) => ({
                Delete: item.Delete,
                Edit: item.Edit,
                ...item
            }))
            this.setState({ rowData: updatedData, isDataFound: true })
        });

    }
    render() {
        return (
            <>
                {/* dangerouslySetInnerHTML={__html: this.state.html} */}
                <div style={{ height: 'calc(78vh - 5px)' }}>
                    <div style={{ position: 'relative', height: "inherit" }}>
                        {
                            this.props.isNavbar &&
                            <div className="_tablenavbar2">
                                <div className="_left">
                                    <span>{this.props.heading}</span>
                                    <span className='Add1' style={{ padding: '5px', fontSize: '0.9rem', background: 'rgb(208, 132, 12)', borderRadius: '10px', cursor: 'pointer' }} onClick={() => { this.Addform() }}>Add +</span>
                                </div>
                                <div className="_right">
                                    <Tooltip title="Refresh">
                                        <IconButton style={{ color: '#fff' }} onClick={() => { this.setState({ isDataFound: false }); this.props.callback(true) }} >
                                            <SyncIcon />
                                        </IconButton>
                                    </Tooltip>
                                    {/* <SyncIcon onClick={() => { this.props.callback(true); this.setState({ isDataFound: false }) }} /> */}
                                    <select onChange={this.handlecategorychange}
                                        style={{ height: '30px', marginRight: '10px', borderRadius: '6px' }} value={this.state.Columnname} >
                                        <option value="">---Select----</option>
                                        {this.state.ColumnData.map((s, index) => {
                                            return (
                                                <option value={s} key={`Column`+index}>
                                                    {s}
                                                </option>
                                            )
                                        })}
                                    </select>

                                    <input type="text" id="searchBarFocus" autoFocus={true} value={this.state.searchValue} style={{ width: "250px", padding: this.state.isShowSearchBox ? "6px 12px" : "6px 0px", marginLeft: this.state.isShowSearchBox ? "10px" : '0px' }} className="editable" placeholder='Search...'
                                        onChange={(e) => this.setState({ searchValue: e.target.value })}
                                    />
                                    {
                                        this.state.isShowSearchBox ?
                                            <Tooltip title="Close Search">
                                                <IconButton style={{ color: '#fff' }} onClick={() => this.clearSearch()}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </Tooltip>

                                            :
                                            null
                                    }
                                    <Tooltip title="Open Search">
                                        <IconButton style={{ color: '#fff' }} onClick={() => this.setState({ isShowSearchBox: true }
                                            , () => {
                                                this.storeSearch(this.state.searchValue);
                                            }
                                        )}
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <span className='Add2' style={{ padding: '5px', fontSize: '0.9rem', background: 'rgb(208, 132, 12)', borderRadius: '10px', cursor: 'pointer' }} onClick={() => { this.Addform() }}>Add +</span>
                                    {/* <span className='BulkUpload' style={{ padding: '5px', fontSize: '0.9rem', background: 'rgb(208, 132, 12)', borderRadius: '10px', cursor: 'pointer' }} onClick={() => { this.setState({OpenBulkUploadModal:true}) }}>Bulk Upload</span> */}
                                </div>
                            </div>
                        }
                        <Table id='_tableGrid' className={'table-hover table2'} responsive style={{ width: '300%' }} >
                            <thead style={this.props.headStyle} >
                                <tr>
                                    {this.state.isDataFound &&
                                        this.state.rowData.length > 0 && this.getHeader(this.state.rowData)}
                                </tr>
                            </thead>
                            {
                                this.state.isDataFound ?
                                    this.state.rowData.length > 0
                                        ?
                                        <tbody>
                                            {
                                                this.getRowsData(this.state.rowData)
                                            }
                                        </tbody>
                                        :
                                                <h3>No Data Found!</h3>
                                    :
                                    <tbody>
                                        {/* <div style={{ marginTop: '2%', marginLeft: '8%', display: 'flex' }}> */}
                                            {/* <h3> */}
                                                <Spinner animation='border' style={{color:'#f9b101'}}/>
                                            {/* </h3> */}
                                        {/* </div> */}
                                    </tbody>
                            }
                        </Table>
                        {/* {
                            this.props.pagination &&
                            this.state.isDataFound &&
                            <div className="_tablefooter">

                                <PaginationBar
                                    rowsPerPage={this.props.pagination.rowsPerPage}
                                    count={this.state.rowData.length}
                                    rowsPerPageOptions={this.props.pagination.rowsPerPageOptions}
                                    onPageChange={(currentPage) => this.setState({ currentPaginationPage: currentPage })}
                                    onRowsChange={(rows) => this.setState({ showPaginationRows: rows, currentPaginationPage: 0 })}
                                />
                            </div>
                        } */}
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Pagination count={this.state.Totalpages} page={this.state.CurrentPage} onChange={(event, page) => this.fetchPage(event, page)} />
                        </div>

                    </div>
                </div>
                <Modal id="documentmodal" centered animation={true} show={this.state.OpenBulkUploadModal} onHide={() => this.setState({ OpenBulkUploadModal: false })} style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
                        <Modal.Header closeButton>
                            <Modal.Title> {'Bulk Upload'}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <div className="importbox">
                            <ImportFile
                                name={'Import'}
                                fetchRsUrl={this.state.fetchRsUrl}
                                reloadTable={this.reloadTable.bind(this)}
                                query={`[dbo].[CRM_Proc_Import_Leads_Submit] @AgentID='${this.state.agentid}', @Json='{0}'`}
                            />
                        </div>
                            <div className="exportbox">
                            <ExportFile 
                                name={'Template'}
                                fetchRsUrl={this.state.fetchRsUrl}
                                query={`[dbo].[CRM_Proc_Import_Leads_Template_getData] @AgentID='${this.state.agentid}'`}
                                downloadUrl={this.state.excelDownloadUrl}
                            />
                            </div>
                        </Modal.Body>
                    </Modal>
                <Modal id="documentmodal" fullscreen centered animation={true} show={this.state.OpenAddScreen} onHide={() => this.setState({ OpenAddScreen: false })} style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
                    <Modal.Header closeButton>
                        <Modal.Title> {this.state.screen == "edit" ? "Edit Product" : " Add Product"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="row" style={{ width: '95%', margin: 'auto' }}>
                            <div className='Fieldstyle' >
                                <div style={{ width: '100%' }}>
                                    <label ><b>SKU</b><span style={{ color: 'red' }}> *</span></label>
                                    <input className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={this.state.sku} onChange={(e) => { this.setState({ sku: e.target.value }) }}></input>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label ><b>Name</b><span style={{ color: 'red' }}> *</span></label>
                                    <input className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={this.state.name} onChange={(e) => { this.setState({ name: e.target.value }) }}></input>
                                </div>
                            </div>
                            <div className='Fieldstyle' >
                                <div style={{ width: '100%' }}>
                                    <label ><b>Description</b><span style={{ color: 'red' }}> *</span></label>
                                    <textarea className="signupinput" style={{ marginTop: '5px', maxHeight: '80px', minHeight: '80px' }} value={this.state.description} onChange={(e) => { this.setState({ description: e.target.value }) }}></textarea>
                                </div>
                            </div>
                            <div className='Fieldstyle' >
                                <div style={{ width: '100%' }}>
                                    <label ><b>Weight </b><span style={{ color: 'red' }}> *</span></label>
                                    <input type="number" step="any" className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={this.state.Weight} onChange={(e) => { this.setState({ Weight: e.target.value }) }}></input>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label ><b>Length </b><span style={{ color: 'red' }}> *</span></label>
                                    <input type="number" step="any" className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={this.state.Length} onChange={(e) => { this.setState({ Length: e.target.value }) }}></input>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label ><b>Width  </b><span style={{ color: 'red' }}> *</span></label>
                                    <input type="number" step="any" className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={this.state.Width} onChange={(e) => { this.setState({ Width: e.target.value }) }}></input>
                                </div>
                            </div>
                            <div className='Fieldstyle' >
                                <div style={{ width: '100%' }}>
                                    <label ><b>Height  </b><span style={{ color: 'red' }}> *</span></label>
                                    <input type="number" step="any" className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={this.state.Height} onChange={(e) => { this.setState({ Height: e.target.value }) }}></input>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label ><b>MRP  </b><span style={{ color: 'red' }}> *</span></label>
                                    <input type="number" step="any" className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={this.state.mrp} onChange={(e) => { this.setState({ mrp: e.target.value }) }}></input>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label ><b>WholesalePrice   </b><span style={{ color: 'red' }}> *</span></label>
                                    <input type="number" step="any" className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={this.state.WholesalePrice} onChange={(e) => { this.setState({ WholesalePrice: e.target.value }) }}></input>
                                </div>
                            </div>
                            <div className='Fieldstyle' >
                                <div style={{ width: '100%' }}>
                                    <label ><b>OverseasPrice   </b><span style={{ color: 'red' }}> *</span></label>
                                    <input type="number" step="any" className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={this.state.OverseasPrice} onChange={(e) => { this.setState({ OverseasPrice: e.target.value }) }}></input>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label ><b>Category</b><span style={{ color: 'red' }}> *</span></label>
                       <Additionlisttypedata listdata={this.state.Categorydata} itemdata={this.state.Category} setdata={(data)=>{this.setState({Category:data})
                        console.log(data,"data")
                    }}/>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label ><b>Subcategory</b><span style={{ color: 'red' }}> *</span></label>
                                    <Additionlisttypedata listdata={this.state.subcategorydata} itemdata={this.state.Subcategory} setdata={(data)=>{this.setState({Subcategory:data})
                    }} />
                                </div>
                            </div>
                            <div className='Fieldstyle' >
                                <div style={{ width: '100%' }}>
                                    <label ><b>Images </b><span style={{ color: 'red' }}> *</span></label>
                                    <input type='file' className="signupinput" style={{ marginTop: '5px', height: '40px' }} onChange={this.handlechangeinput}/>
                                    {
                                        <div className='d-flex'>{this.state.ImageArr.map((ele,ind)=>{
                                            return(
                                                <>
                                                <div className='disdata' style={{cursor:'pointer'}}>
                                                <img src={ele} className='img-sec img-fluid' onClick={()=>this.handleshowimage(ele)} onError={(e)=>{e.target.src = 'https://seetechparts.com/uploads/2023/11/dummy.png'}}/>
                                                {/* <img src='https://seetechparts.com/uploads/2023/11/dummy.png' className='img-fluid'/> */}
                                                <span className='disclose' onClick={()=>this.handleDelete(ind)}><RxCross2 /></span>
                                                </div>
    
                                                </>
                                            )
                                        })}</div>
                                    }
                                </div>
                            </div>
                            {this.state.showimage &&  <Modal  show={this.state.showimage} onHide={() => this.setState({ showimage: false })} style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)'}}>
                                            <Modal.Header closeButton></Modal.Header>
                                                <Modal.Body>
                                                <img src={this.state.currentimage} className='img-fluid' onError={(e)=>{e.target.src = 'https://seetechparts.com/uploads/2023/11/dummy.png'}}/>
                                                {/* <img src='https://seetechparts.com/uploads/2023/11/dummy.png' className='img-fluid'/> */}
                                                </Modal.Body>
                                                </Modal>}
                            <div className='Fieldstyle' >
                                <div style={{ width: '100%' }}>
                                    <label ><b>SuitableForModel</b><span style={{ color: 'red' }}> *</span></label>
                                    <Additionlisttypedata listdata={this.state.SuitableForModeldata} itemdata={this.state.SuitableForModel} setdata={(data)=>{this.setState({SuitableForModel:data})}}/>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label ><b>SuitableForSubModel    </b><span style={{ color: 'red' }}> *</span></label>
                                <Additionlisttypedata listdata={this.state.SuitableForSubModeldata} itemdata={this.state.SuitableForSubModel} setdata={(data)=>{this.setState({SuitableForSubModel:data})}}/>
                                </div>
                            </div>
                            <div className='Fieldstyle' >
                                <div style={{ width: '100%' }}>
                                    <label ><b>SuitableForPartReference </b><span style={{ color: 'red' }}> *</span></label>
                                    <input className="signupinput" style={{ marginTop: '5px', height: '40px' }}
                                        value={this.state.SuitableForPartReference} onChange={(e) => { this.setState({ SuitableForPartReference: e.target.value }) }}></input>
{/* <Additionlisttypedata listdata={this.state.SuitableForPartReferencedata} itemdata={this.state.SuitableForPartReference} setdata={(data)=>{this.setState({SuitableForPartReference:data})}}/> */}

                                </div>
                                <div style={{ width: '100%' }}>
                                    <label ><b>CompanyCode     </b><span style={{ color: 'red' }}> *</span></label>
                                    {/* <input className="signupinput" style={{ marginTop: '5px', height: '40px' }}
                                        value={this.state.CompanyCode} onChange={(e) => { this.setState({ CompanyCode: e.target.value }) }}></input> */}
                               <Additionlisttypedata listdata={this.state.CompanyCodedata} itemdata={this.state.CompanyCode} setdata={(data)=>{this.setState({CompanyCode:data})}}/>
                                </div>
                            </div>
                            <div className='Fieldstyle' >
                                <div style={{ width: '100%' }}>
                                    <label ><b>WarehouseLocation   </b><span style={{ color: 'red' }}> *</span></label>
                                    {/* <input className="signupinput" style={{ marginTop: '5px', height: '40px' }}
                                        value={this.state.WarehouseLocation} onChange={(e) => { this.setState({ WarehouseLocation: e.target.value }) }}></input> */}
                                   <Additionlisttypedata listdata={this.state.WarehouseLocationdata} itemdata={this.state.WarehouseLocation} setdata={(data)=>{this.setState({WarehouseLocation:data})}}/>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label ><b>RackSection  </b><span style={{ color: 'red' }}> *</span></label>
                                   <Additionlisttypedata listdata={this.state.RackSectiondata} itemdata={this.state.RackSection} setdata={(data)=>{this.setState({RackSection:data})}}/>
                                </div>
                            </div>
                            <div className='Fieldstyle' >
                                <div style={{ width: '100%' }}>
                                    <label ><b>RackCode</b><span style={{ color: 'red' }}> *</span></label>
                                    <Additionlisttypedata listdata={this.state.RackCodedata} itemdata={this.state.RackCode} setdata={(data)=>{this.setState({RackCode:data})}}/>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label ><b>PouchTubeCode</b><span style={{ color: 'red' }}> *</span></label>
                                    <Additionlisttypedata listdata={this.state.PouchTubeCodedata} itemdata={this.state.PouchTubeCode} setdata={(data)=>{this.setState({PouchTubeCode:data})}}/>
                                </div>
                            </div>
                            <div className='Fieldstyle' >
                                <div style={{ width: '100%' }}>
                                    <label ><b>BoxCode </b><span style={{ color: 'red' }}> *</span></label>
                                    {/* <input className="signupinput" style={{ marginTop: '5px', height: '40px' }}
                                        value={this.state.BoxCode} onChange={(e) => { this.setState({ BoxCode: e.target.value }) }}></input> */}
                                   <Additionlisttypedata listdata={this.state.BoxCodedata} itemdata={this.state.BoxCode} setdata={(data)=>{this.setState({BoxCode:data})}}/>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label ><b>HSNCode </b><span style={{ color: 'red' }}> *</span></label>
                                  <Additionlisttypedata listdata={this.state.HSNCodedata} itemdata={this.state.HSNCode} setdata={(data)=>{this.setState({HSNCode:data})}}/>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label ><b>GST  </b><span style={{ color: 'red' }}> *</span></label>
                                    <Additionlisttypedata listdata={this.state.GSTdata} itemdata={this.state.GST} setdata={(data)=>{this.setState({GST:data})}}/>
                                </div>
                            </div>
                            <div className='Fieldstyle' >
                                <div style={{ width: '100%' }}>
                                    <label ><b>Make </b><span style={{ color: 'red' }}> *</span></label>
                                        <Additionlisttypedata listdata={this.state.Makedata} itemdata={this.state.Make} setdata={(data)=>{this.setState({Make:data})}}/>

                                </div>
                                <div style={{ width: '100%' }}>
                                    <label ><b>ProductCondition </b><span style={{ color: 'red' }}> *</span></label>
                                   <Additionlisttypedata listdata={this.state.ProductConditiondata} itemdata={this.state.ProductCondition} setdata={(data)=>{this.setState({ProductCondition:data})}}/>
                                </div>
                            </div>
                            <div className='Fieldstyle' >
                                <div style={{ width: '100%' }}>
                                    <label ><b>Brand  </b><span style={{ color: 'red' }}> *</span></label>
<Additionlisttypedata listdata={this.state.Branddata} itemdata={this.state.Brand} setdata={(data)=>{this.setState({Brand:data})}}/>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label ><b>CountryOfOrigin  </b><span style={{ color: 'red' }}> *</span></label>
                                   <Additionlisttypedata listdata={this.state.CountryOfOrigindata} itemdata={this.state.CountryOfOrigin} setdata={(data)=>{this.setState({CountryOfOrigin:data})}}/>
                                </div>
                            </div>
                            <div className='Fieldstyle' >
                                <div style={{ width: '100%' }}>
                                    <label ><b>ConversionRate</b><span style={{ color: 'red' }}> *</span></label>
                                    <input type='number' className="signupinput" style={{ marginTop: '5px', height: '40px' }}
                                        value={this.state.ConversionRate} onChange={(e) => { this.setState({ ConversionRate: e.target.value }) }} onClick={() => { this.setState({ ConversionRate: '' }) }}></input>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label ><b>MOQ </b><span style={{ color: 'red' }}> *</span></label>
                                    <input type='number' className="signupinput" style={{ marginTop: '5px', height: '40px' }}
                                        value={this.state.MOQ} onChange={(e) => { this.setState({ MOQ: e.target.value }) }} onClick={() => { this.setState({ MOQ: '' }) }}></input>
                                </div>
                            </div>
                            <div className='Fieldstyle' >
                                <div style={{ width: '100%' }}>
                                    <label ><b>VisibilityDomestic </b><span style={{ color: 'red' }}> *</span></label>
                                    <input type='checkbox' style={{ marginLeft: '10px' }} checked={this.state.VisibilityDomestic ? true : false} onChange={(e) => { this.setState({ VisibilityDomestic: e.target.checked == true ? 1 : 0 }) }}></input>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label ><b>VisibilityOverseas </b><span style={{ color: 'red' }}> *</span></label>
                                    <input type='checkbox' checked={this.state.VisibilityOverseas ? true : false} style={{ marginLeft: '10px' }} onChange={(e) => { this.setState({ VisibilityOverseas: e.target.checked == true ? 1 : 0 }) }}></input>
                                </div>
                            </div>
                        </div>
                        <div class="row" id="Submitbtn" style={{ marginTop: '20px' }}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {
                                    this.state.screen == 'add' ?
                                        <button style={{ color: 'white', margin: '8px 0px', padding: '10px 30px', backgroundColor: 'black', borderRadius: '10px' }} onClick={(e) => { e.preventDefault(); this.AddProduct() }}>Submit</button>
                                        :
                                        <button style={{ color: 'white', margin: '8px 0px', padding: '10px 30px', backgroundColor: 'black', borderRadius: '10px' }} onClick={() => { this.EditProductData() }}>Submit</button>
                                }
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}